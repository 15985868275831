import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";

import './App.css';
import '../src/styles/all.css'
import '../src/styles/home.css'
import Ajouter from './components/Ajouter'
import All from './components/All'

class App extends Component{

  render(){
  return (
      <div className="App">
        <Router>
          <Route path={process.env.PUBLIC_URL + "/"} exact>
            <Ajouter/>
          </Route>
          <Route path={process.env.PUBLIC_URL + "/all"} exact>
            <All/>
          </Route>
       </Router>
      </div>
    );
  }
}
export default App;
