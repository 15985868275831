import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

class All extends Component{
constructor(props){
     super(props);
     this.state = {
        allTransactions: null
     }
}

componentDidMount(){
    var xmlhttp = new XMLHttpRequest();

	xmlhttp.onreadystatechange = function() {
	  if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
        var data = JSON.parse(xmlhttp.responseText);
            this.setState({
                allTransactions: data
            })
		}
	}.bind(this)  
		xmlhttp.open("POST", "transactionsGet.php", true);
		xmlhttp.send(); 
}

returnAllTransactions(){
    if(this.state.allTransactions !== null){
        return this.state.allTransactions.map( (e, index)=>{
            return <div className="transaction-el">
            <p>{e.dateTransaction}</p>
            <p>{e.nomTransaction}</p>
            <p>{e.quantity}</p>
            <p>{e.coutTransaction}</p>
            <p>{e.nomClient}</p>
            <p>{e.email}</p>
            </div>
        })
    }
}
refreshPage(){
   
        
    window.location.href = window.location.href;

}
gererAddATransaction(){
    this.props.history.push(process.env.PUBLIC_URL + "/");
}

  render(){
  return (
      <div className="All">
          <h1>All Transactions</h1>
          <p onClick={()=>{this.gererAddATransaction()}} className="addATransaction">Add a Transaction</p>
          <p onClick={()=>{this.refreshPage()}} className="addATransaction">Refresh</p>
    <div className="titles">
        <p>Date</p>
        <p>Name</p>
        <p>Quantity</p>
        <p>Cost</p>
        <p>Client name</p>
        <p>Email</p>
    </div>
     {this.returnAllTransactions()}
     <div className="transaction-el">
            <p>2020-02-12</p>
            <p>olivier</p>
            <p>1</p>
            <p>50</p>
            <p>Olivier Larose</p>
            <p>olivierlarose1@gmail.com</p>
            </div>
    {/* <div className="transaction-el">
        <p>1er septembre 2019</p>
        <p>Achat dune plante rare</p>
        <p>1</p>
        <p>120</p>
        <p>Olivier Larose</p>
        <p>olivierlarose1@gmail.com</p>
    </div>
    <div className="transaction-el">
        <p>1er septembre 2019</p>
        <p>Achat dune plante rare</p>
        <p>1</p>
        <p>120</p>
        <p>Olivier Larose</p>
        <p>olivierlarose1@gmail.com</p>
    </div>
    <div className="transaction-el">
        <p>1er septembre 2019</p>
        <p>Achat dune plante rare</p>
        <p>1</p>
        <p>120</p>
        <p>Olivier Larose</p>
        <p>olivierlarose1@gmail.com</p>
    </div>
    <div className="transaction-el">
        <p>1er septembre 2019</p>
        <p>Achat dune plante rare</p>
        <p>1</p>
        <p>120</p>
        <p>Olivier Larose</p>
        <p>olivierlarose1@gmail.com</p>
    </div> */}
      </div>
      
    );
  }
}
export default withRouter(All);
