import React, {Component} from 'react';
//import '../styles/home.css';
import { withRouter } from 'react-router-dom';



class Ajouter extends Component{

constructor(props){
    super(props);
    this.nomTransaction = React.createRef();
    this.dateTransaction = React.createRef();
    this.nomClient = React.createRef();
    this.emailClient = React.createRef();
    this.quantity = React.createRef();
    this.coutTransaction = React.createRef();
    this.password = React.createRef();
}

componentDidMount(){

}

gererSubmit(){
  if(this.password.current.value == "123"){
  

    //const info = "nomTransaction=this.nomTransaction.current.value&dateTransaction=this.dateTransaction.current.value&nomClient=this.nomClient.current.value&coutTransaction=this.coutTransaction.current.value"
  
  var xmlhttp = new XMLHttpRequest();
	xmlhttp.onreadystatechange = function() {
	  if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      
      let data = JSON.parse(xmlhttp.responseText);


		}
	}.bind(this)  
    xmlhttp.open("POST", "transactionsPost.php", true);
    xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xmlhttp.send("nomTransaction=" + this.nomTransaction.current.value + "&dateTransaction=" + this.dateTransaction.current.value + "&nomClient=" + this.nomClient.current.value + "&coutTransaction=" + this.coutTransaction.current.value + "&quantity=" + this.quantity.current.value  + "&email=" + this.emailClient.current.value);  
    
    alert("transaction successful")

    this.nomTransaction.current.value = ""
    this.dateTransaction.current.value = ""
    this.nomClient.current.value = ""
    this.coutTransaction.current.value = ""
    this.quantity.current.value = 1
    this.emailClient.current.value = ""
    this.password.current.value = ""
  }
  else if(this.password.current.value != "123"){
    alert("incorrect password")
  }
  else{
    alert("error")
  }
  
}

gererViewAll(){
  this.props.history.push(process.env.PUBLIC_URL + "/all");
}

  render(){
  return (
      <div className="Home">
        <form>
          <fieldset>
            <legend>Hydroflora form</legend>
              <div class="transaction-name">
              <p>Transaction name</p>
              <input ref={this.nomTransaction} type="text"/>
              </div>
              <div class="transaction-name">
            <p>Transaction cost</p>
            <input ref={this.coutTransaction} type="text"/>
            </div>
            <div class="transaction-name">
                <p>Client name</p>
                <input ref={this.nomClient} type="text"/>
            </div>
            <div class="transaction-name">
                <p>Client email</p>
                <input ref={this.emailClient} type="text"/>
            </div>
            <div class="transaction-name">
                <p>Quantity</p>
                <select ref={this.quantity} id="cars" name="cars">
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
            </div>
            <div class="transaction-name">
                <p>Date</p>
                <input ref={this.dateTransaction} type="date"/>
            </div>
            <div class="checkout-container">
                <p>Password</p>
                <input ref={this.password} type="text"/>
                <input onMouseDown={this.gererSubmit.bind(this)} type="button" value="Confirm"/>
            </div>
            <p onClick={()=>{this.gererViewAll()}} className="viewAll">View all transactions</p>
          </fieldset>
        </form>

      </div>
      
    );
  }
}
export default withRouter(Ajouter);
